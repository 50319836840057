.fr-view .fr-class-highlighted {
    background-color: #ffff00;
}

.fr-view .fr-class-blue {
    background-color: #333333;
}

.fr-view .fr-class-code {
    border-color: #cccccc;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #f5f5f5;
    padding: 10px;
    font-family: 'Courier New', Courier, monospace;
}

.fr-view .fr-class-transparency {
    opacity: 0.5;
}

.tblLeftPadding tbody tr {
    margin-left: 10px;
}
.tblTopPadding tbody tr {
    padding-top: 10px;
}

.class2 thead tr th,
.class2 tbody tr td {
    border-style: dashed;
}

.tblCellLeftPadding {
    padding-left: 10px;
}
.tblCellTopPadding {
    padding-top: 10px;
}

.tblCellClass2 {
    font-weight: 300;
    font-size: 16px;
}

.imageRadius {
    border-radius: 8px;
}
.imageBorder {
    border: 2px solid #efefef;
}

.fr-view img.fr-dii.fr-fil {
    margin: 0px 0px 0px 0px;
}

.fr-view img.fr-dii {
    margin: 0px 0px 0px 0px;
}

.fr-view img.fr-dib {
    margin: 0px 0px 0px 0px;
}

.fr-view img.fr-dib.fr-fil {
    margin: 0px 0px 0px 0px;
}

.fr-view table td {
    border: 0px solid #ddd;
}

.fr-fic .fr-dib {
    width: '100%' !important;
}

.froala-content img {
    max-width: 100%;
    height: auto;
  }
  
  .froala-content table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .froala-content table td,
  .froala-content table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .markdown-content h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content h3 {
    font-size: 1.17em;
    margin-bottom: 0.5em;
  }
  
  .markdown-content blockquote {
    border-left: 4px solid #ccc;
    margin-left: 0;
    padding-left: 1em;
    color: #666;
  }
  
  .markdown-content pre {
    background-color: #f4f4f4;
    padding: 1em;
    overflow-x: auto;
  }

  [data-cmd="markdown"] {
    display: none !important;
  }